:root {
  --primary: #84CEEB;
  --secondary: #5680e9;
  --table-blue: 	#E4FBFF;
  --table-sub: #E8F0F2;
  --white: #ffffff;
  --grey: #f5f5f5;
}

*,
*::after,
*::before  {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root, .App {
 height: 100vh;
  width: 100%;
  background: var(--primary);
}

html {
  font-size: 62.5%;
}

body {
  font-family:"Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
  box-sizing: border-box;
  padding-top: 65px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

td {
  text-align:center;
}

table {
  width: 50%;
}

table, th, td {
  border-collapse: collapse;
  border: none;
}

tr:hover {
  background-color: var(--table-blue);
}

th {
  background-color: var(--secondary);
  color: white;
}

th, td{
  padding: 15px;
  text-align: left;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
}

.align-center{
  align-items: center;
  padding: 0;
}

.align-right {
  align-self: flex-end;
}

.space-around{
  justify-content: space-around;
}

.space-between {
  justify-content: space-between
}

.end {
  justify-content: flex-end;
}

.opacity {
  opacity: 0.7;
}

.cursor{
  cursor:pointer;
}

.margin-btm-sm {
  margin-bottom: 1.5rem;
}

.margin-top-sm {
  margin-top: 1.rem;
}

.layout{
  position: absolute;
  top: 0;
  background: var(--secondary);
  width: 100%;
  height: 6rem;
  z-index: 100;
}

.full-height {
  height: 100%;
}

li.nav-item{
  color: #ffff;
  font-size: 1.5rem;
  cursor:pointer;
}

@media screen and (min-width: 384px) {
  li.nav-item {
    font-size: 2rem;
  }

  .navtab {
    margin-left: 3rem;
  }
}

@media screen and (min-width: 500px) {
  li.nav-item {
    font-size: 3rem;
  }
}

.text-center-red {
  text-align: center;
  color: red;
}

.text-center {
  text-align: center;
}

.margin-sm {
  margin: 0.5rem;
}

.margin-r-sm {
  margin-right: 1rem;
}

.nav-item:hover {
  box-shadow: inset 0 0 10rem 10rem rgba(255, 255, 255, 0.3);
}


.nav-item-active {
  padding-top: .5rem;
  border-bottom: .5rem solid #fff;
}

.nav-item-inactive:hover {
  padding-top: .5rem;
  border-bottom: .5rem solid #7DEDFF;
}

.navtab {
  margin-left: 1rem;
  cursor: pointer;
  
}

.logo {
  color: #ffff;
  font-size: 1.5rem;
}
@media screen and (min-width: 384px) {
  .logo {
    font-size: 2rem;
  }
}
@media screen and (min-width: 500px) {
  .logo {
    font-size: 3rem;
  }
}
.timerlist {
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;
  background: var(--primary);
  
  display: grid;
  grid-template-columns: 0.5fr  repeat(10, [col-start] 1fr [col-end]) 0.5fr;
}

.timerGrid {
  grid-column: col-start 1 / col-end 10; 
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(30rem,1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
}

.add-btn {
  background-color: #DEEDFE;
  border: none;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: 5px;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  outline:none;
  width: 15rem;
  height: 15rem;
  transition: 0.5s ease;
}

.timer-container {
  min-height: 30rem;
  min-width: 30em;
  background-image: linear-gradient(to bottom right, #99cff7, #6cbbf6);
  border-radius: 2.5rem;
  opacity: 0.9;
  margin: 1rem;
  transition: 0.5s ease;
  box-shadow: 0 .8rem 1.5rem rgba(0, 0, 0, 0.1);
}

.labels{
  padding-left: 3rem;
  font-size: 3rem;
  font-weight: 70rem;
  color: #fff;
}

.editable-btn {
  background-color: transparent;
  border:none;
  outline: none;
  visibility: hidden;
}

.delete-btn-container{
  position: absolute;
  right: 0;
  top: 0;
 }

 .delete-timer-btn{
  background-color: transparent;
  border: none;
  border-radius: 50%;
  text-decoration: none;
  outline:none;
  visibility: hidden;
  padding-top: .2rem;
  margin-right: .7rem;
  margin-top: .5rem;
  width: 3rem;
  height: 3rem;
}

.time-btn{
  background-color: #3493d9;
  border: none;
  border-radius: 50%;
  margin-bottom: 1rem;
  margin-right: .5rem;
  font-size: 3rem;
  text-align: center;
  text-decoration: none;
  outline:none;
  width: 4rem;
  height: 4rem;
  transform: translateY(3rem);
  box-shadow: 0px 8px 15px rgba(108,187,246, 0.4);
}

.timer-container:hover .delete-timer-btn{
  visibility: visible;
}

.hover-scale:hover {
  transform: scale(1.04);
}

.label-container:hover .editable-btn{
  visibility: visible;
}

.collapse {
 padding: 0px;
  width: 100%;
  overflow: hidden;
  transition: .5s ease;
  
}

.timerData {
  background: #468dc9;
}

.timerHistory {
  background: var(--table-sub);
}

.timerDataTable {
  width: 100%;
  padding: 0 1rem;
  border-radius: .25rem;
  overflow: auto;
}

.analyticsContainer {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.timerTable {
  width: 100%;
  background-color: #fefefe;
  border-radius: .25rem;
}

.dropdown-btn {
  height: 100%;
  cursor: pointer;
  color: #ffff;
  font-size: 3rem;
  padding-left: 2rem;
}

.dropdown-btn:hover {
  box-shadow: inset -9rem 0 0 0 rgba(255, 255, 255, 0.3);
}

.circle {
  background-color: #ccc;
  border-radius: 50%;
  text-align: center;
  height: 4rem;
  width: 4rem;
}
.initials {
  font-size: 2rem;
  line-height: 2;
  position: relative;
  top: 1rem;  
}

.expand{
  margin-top: 2rem;
  margin-right: 1rem;
  color:'#fefefe';
}

.expand-arrow-down {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}
.expand-arrow-up {
  transform: rotate(180deg);
  transition: transform 0.1s linear;
}

.dropdown {
  position: absolute;
  right: 0;
  display: inline-block;
  height: 100%;
}

.dropdown-content {
  position: relative;
  background-color: #5AA3DD;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-profile{
  background:#365fc7;
  color: #ffff;
  font-size: 3rem;
}

.create-timer {
  color: black;
}

.create-timer-label {
  font-size: 1.5rem;
  font-weight: 70rem;
}

.page-mask{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
}

.form {
  padding: 30px 40px;	
}

.form h2 {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid var(--grey); 
  line-height: 0.1em;
  margin: 10px 0 20px; 
}

.form h2 span {
    background:#fff; 
    padding:0 10px; 
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;
	padding-bottom: 5px;
	position: relative;
}

.form-outer{
  position: fixed;

  width: 50rem;
  height: 30rem;
  top: 50%;
  left: 50%;
  margin-top: -15rem;
  margin-left: -25rem;
  border: 1px solid var(--secondary);
  border-radius: .25rem;
  background-color: #fefefe;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);

  overflow: hidden;
  z-index: 200;
}

.header {
  font-size: 2rem;
  border-bottom: 1px solid #f0f0f0;
	background-color: #365fc7;
	padding: 20px 40px;
  color: #fefefe;
  text-align: center;
}

.form-outer h2 {
  margin: 0;
}

.form-group label {
  color: black;
  font-size: 1.5rem;
  font-weight: 600;
  display: inline-block;
	margin-bottom: 5px;
}

.input {
    border: 2px solid #f0f0f0;
    border-radius: 4px;
    display: block;
    font-family: inherit;
    font-size: 14px;
    padding: 10px;
    width: 100%;
}

.form-group input:focus {
  outline: 0;
	border-color: var(--secondary);
}

.form button {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
	border-radius: 4px;
	font-family: inherit;
	font-size: 16px;
	padding: 10px;
	margin-top: 20px;
	width: 100%;
  margin-bottom: 1.5rem;
}

.btn {
  color: #fff;
  background-color: #365fc7;
	border: 2px solid #365fc7;
}


.auth-form-outer{
  max-width: 50%;
  margin: auto;
  border: 1px solid var(--secondary);
  border-radius: .25rem;
  background-color: #fefefe;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);

  overflow: hidden;
  z-index: 200;
}

@media screen and (min-width: 0px) {
  .auth-form-outer {
    max-width: 80%;
  }
}

@media screen and (min-width: 621px) {
  .auth-form-outer {
    max-width: 50%;
  }
}

@media screen and (min-width: 900px) {
  .auth-form-outer {
    max-width: 40%;
  }
}

@media screen and (min-width: 1024px) {
  .auth-form-outer {
    max-width: 30%;
  }
}

.auth-form-outer h2 {
  margin: 0;
}

.login-label {
  position: relative;
  top:0;
  right: 0;
}

.order {
  order: 99999999;
}

.chart-container {
  background-color: #fefefe;
  margin-top: 1rem;
  padding: 3rem;
  border-radius: 3px;
  text-align: center;
  max-width: 95%;
  width: auto;
}

.chart {
  overflow-x: auto;
}

.google-btn {
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;

  background-color: white;
}

.btn-icon {
  margin-right: auto;
}

.btn-span {
  margin-right: auto;
}